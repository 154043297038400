import React, { Component } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import {
  AuthForm,
  FormInputLoginContainer,
  StyledLabel,
  SubmitButton,
  LoginDecorator
} from '../../StylesPages/loginStyle'
import { TitleCard } from '../../StylesPages/membershipStyle'
import { InputContainer, StyledInput, StyledError, CustomButton } from '../../Collapse/stepped/style'
import { ButtonContainer } from '../../Button/style'
import PropTypes from 'prop-types'
import withLocation from '../withLocation'

class PasswordSection extends Component<{}, any> {
  MESSAGE = 'Por favor dirígete a la página de inicio de sesión.'

  constructor(props: any) {
    super(props)
    this.state = {
      showForm: true,
      customerId: null,
      identification: null,
      userId: null,
      message: ``,
      fields: {},
      errors: {}
    }
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleSubmit(event: any) {
    const { customerId, userId, identification } = this.state
    const { password } = this.state.fields
    event.preventDefault()
    if (this.handleValidation()) {
      if (customerId) {
        this.generatePassword(customerId, password)
        return
      }
      if (userId) {
        this.updatePassword(userId, password)
        return
      }
      if (identification) {
        this.generatePasswordForExistingUser(identification, password)
      }
      this.setState({
        showForm: false,
        message: `No existe un usuario para generar la contraseña. Por favor dirígete a la página de inicio de sesión e intenta nuevamente.`
      })
    }
  }

  handleValidation = () => {
    let fields = this.state.fields
    let errors: any = {}
    let formIsValid = true

    if (!fields['password']) {
      formIsValid = false
      errors['password'] = 'Contraseña requerida'
    }

    if (typeof fields['password'] !== 'undefined') {
      if (fields['password'].length < 8) {
        formIsValid = false
        errors['password'] = 'La contraseña debe tener mínimo 8 caracteres'
      }
      let count = 0
      count += /[a-z]/.test(fields['password']) ? 1 : 0
      count += /[A-Z]/.test(fields['password']) ? 1 : 0
      if (count < 2) {
        formIsValid = false
        errors['password'] = 'La contraseña debe tener letras minúsculas y mayúsculas'
      }
    }

    if (!fields['repeatPassword']) {
      formIsValid = false
      errors['repeatPassword'] = 'Debes repetir tu contraseña'
    }

    if (
      typeof fields['repeatPassword'] !== 'undefined' &&
      typeof fields['password'] !== 'undefined'
    ) {
      if (fields['repeatPassword'] != fields['password']) {
        formIsValid = false
        errors['repeatPassword'] = 'Las contraseñas no coinciden'
      }
    }

    this.setState({ errors })
    return formIsValid
  }

  generatePassword(customerId: number, password: string) {
    axios
      .post(`${process.env.API_URL}customer/generate-password`, { customerId, password }, {})
      .then(() => {
        this.setState({
          showForm: false,
          message: `Tu contraseña fue generada correctamente. ${this.MESSAGE}`
        })
      })
      .catch(error => {
        this.setState({
          showForm: false,
          message: `Usuario no encontrado. ${error.getMessage()}`
        })
      })
  }

  updatePassword(id: number, password: string) {
    axios
      .post(`${process.env.API_URL}customer/update-password`, { id, password }, {})
      .then(() => {
        this.setState({
          showForm: false,
          message: `Tu contraseña fue actualizada correctamente. ${this.MESSAGE}`
        })
      })
      .catch(error => {
        this.setState({
          showForm: false,
          message: `Usuario no encontrado. ${error}`
        })
      })
  }

  generatePasswordForExistingUser(identification: string, password: string) {
    axios
      .post(`${process.env.API_URL}customer/generate-password-enrollment`, {
        identification,
        password
      })
      .then(() => {
        this.setState({
          showForm: false,
          message: `Tu nueva contraseña fue generada correctamente. ${this.MESSAGE}`
        })
      })
  }

  showPasswordForm() {
    const { errors, fields } = this.state
    return (
      <AuthForm
        display={this.state.showForm}
        method="post"
        onSubmit={(event: any) => {
          this.handleSubmit(event)
        }}
      >
        <TitleCard className="center-title">Crear contraseña</TitleCard>
        <FormInputLoginContainer>
          <StyledLabel>Contraseña segura</StyledLabel>
          <InputContainer>
            <StyledInput
              placeholder="Ingresa una contraseña segura"
              error={errors['password']}
              type="password"
              name="password"
              onChange={this.handleUpdate.bind(this, 'password')}
              value={fields['password']}
            />
            {errors['password'] && <StyledError>{errors['password']}</StyledError>}
          </InputContainer>
        </FormInputLoginContainer>
        <FormInputLoginContainer>
          <StyledLabel>Repita contraseña segura</StyledLabel>
          <InputContainer>
            <StyledInput
              placeholder="Repite la contraseña"
              error={errors['repeatPassword']}
              type="password"
              name="repeatPassword"
              onChange={this.handleUpdate.bind(this, 'repeatPassword')}
              value={fields['repeatPassword']}
            />
            {errors['repeatPassword'] && <StyledError>{errors['repeatPassword']}</StyledError>}
          </InputContainer>
        </FormInputLoginContainer>
        <div className="password-text">
          La contraseña debe tener mínimo 8 caracteres, una letra mayúscula y una minúscula.
        </div>
        <ButtonContainer>
          <SubmitButton type="submit" value="Guardar" />
        </ButtonContainer>
      </AuthForm>
    )
  }

  showPasswordMessages() {
    return (
      <AuthForm
        display={!this.state.showForm}
        method="post"
        onSubmit={(event: any) => {
          event.preventDefault()
          this.generatePassword(this.state.password, window.history.state.customerId)
        }}
      >
        <TitleCard className="center-title">Crear contraseña</TitleCard>
        <div className="password-text">{this.state.message}</div>
        <LoginDecorator className="row">
          <div className="line" />
          <div>
            <i className="icon-grande-table-02" />
          </div>
          <div className="line" />
        </LoginDecorator>
        <ButtonContainer className="secondary-btn-container">
          <CustomButton className="secondary-btn-brown" onClick={() => navigate('/login')}>
            Iniciar sesión
          </CustomButton>
        </ButtonContainer>
      </AuthForm>
    )
  }

  validateToken(token: string) {
    axios
      .post(`${process.env.API_URL}customer/validate-token`, { token }, {})
      .then((response: any) => {
        const { errorCode, isAllowedToChangePassword, userId } = response.data
        this.setState({
          userId,
          showForm: isAllowedToChangePassword,
          message: this.recoverMessage(errorCode)
        })
      })
      .catch(error => {
        this.setState({
          showForm: false,
          message: `Usuario no encontrado. ${error.getMessage()}`
        })
      })
  }

  validateCustomerToken(token: string) {
    axios
      .post(`${process.env.API_URL}customer/validate-customer-token`, { token }, {})
      .then((response: any) => {
        const { errorCode, isAllowedToChangePassword, identification } = response.data
        this.setState({
          identification,
          showForm: isAllowedToChangePassword,
          message: this.recoverMessage(errorCode)
        })
      })
      .catch(error => {
        this.setState({
          showForm: false,
          message: `Usuario no encontrado. ${error.getMessage()}`
        })
      })
  }

  recoverMessage(errorCode: number) {
    switch (errorCode) {
      case 1:
        return `El token no es válido.`
      case 2:
        return `El token no existe o ha ya fue utilizado.`
      case 3:
        return `El token ha expirado`
    }
  }

  componentDidMount = () => {
    if (window.history.state) {
      this.setState({
        customerId: window.history.state.customerId
      })
      return
    }
    const { search } = this.props
    if (search && search.token && search.enrollment) {
      if (search.enrollment === '1') {
        this.validateCustomerToken(search.token)
        return
      }
      this.validateToken(search.token)
    }
  }

  render() {
    return (
      <div>
        {this.showPasswordForm()}
        {this.showPasswordMessages()}
      </div>
    )
  }
}

PasswordSection.propTypes = {
  search: PropTypes.object
}

export default withLocation(PasswordSection)
